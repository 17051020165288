<template>
    <div>
        <JPrint ref="doPrint"></JPrint>

        <a-form-model layout="inline" :model="form">
            <a-form-model-item label="">
                <a-select style="width: 160px" v-model="form.status">
                    <a-select-option value=''>全部样品</a-select-option>
                    <a-select-option value='1'>领取任务</a-select-option>
                    <a-select-option value='2'>申领样品</a-select-option>
                    <a-select-option value='5'>已领取</a-select-option>
                    <a-select-option value='6'>申请退回样品</a-select-option>
                    <a-select-option value='8'>开始检测</a-select-option>
                </a-select>
            </a-form-model-item>

            <a-form-model-item label="">
                <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" v-model="form.spec_type" :options="Samplecategory_list" placeholder="请选择"/>
            </a-form-model-item>

            <a-form-model-item label="">
                <a-input placeholder="请输入样品名称编号进行搜索" style="width: 200px" v-model="form.keywords"/>
            </a-form-model-item>

            <a-form-model-item label="">
                <a-button type="danger" @click="Clear()">清空</a-button>
            </a-form-model-item>

            <a-form-model-item label="">
                <div class="print-tag">
                    <a-button type="primary">打印选中样品编号</a-button>
                    <div class="print-show">
                        <div v-for="(item,index) in templateList" :key="item.id"> {{ item.name }}
                            <img :src="getImg(item)" style="width:150px">
                            <a-button type="primary" @click="PrintTAG(index)">打印</a-button>
                        </div>
                    </div>
                </div>
            </a-form-model-item>
            <a-form-model-item style="float: right">
                <a-button type="primary">导出筛选数据</a-button>
            </a-form-model-item>
        </a-form-model>


        <a-space style="margin-top: 10px;" class="btner">

            <div class="btnerlist " @click="Set_specitem_lingqu()">
                <i class="iconfont icon-weituokehuxinxiguanli vm" style="font-size: 16px"></i> 申领样品
            </div>

            <div class="btnerlist" @click="jumpToJiance()">
                <i class="iconfont icon-jianyan2" style="font-size: 18px"></i> 开始检测
            </div>

            <div class="btnerlist" @click="showDrawerSendback()">
                <i class="iconfont icon-jurassic_last" style="font-size: 16px"></i> 退回样品
            </div>

            <div class="btnerlist">
                <i class="iconfont icon-weibiaoti545" style="font-size: 16px"></i> 导出筛选数据
            </div>

        </a-space>
        <a-row>
            <a-col :span="24">
                <table class="layui-table" lay-size="sm" style="margin-top: -9px">
                    <thead>
                    <tr>
                        <th></th>
                        <th>样品类型</th>
                        <th>样品名称</th>
                        <th>样品编号</th>
                        <th>采集信息</th>
                        <th>检测项目</th>
                        <th>检测方法</th>
                        <th>样品最新状态</th>
                        <th>操作</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,key) in Get_list_list">
                        <td>
                            <template v-if="Number(item.create_check_table_id) === 0">
                                <a-checkbox v-model="item.create_check"></a-checkbox>
                            </template>
                        </td>

                        <td>{{item.spec_type_name}}{{item.id}}</td>
                        <td>{{item.spec_item_name}}</td>
                        <td>
                            <a-checkbox v-model="item.check"></a-checkbox>
                            {{item.in_number}}
                        </td>
                        <td>
                            <p>采集人：{{item.caiji_name}}</p>
                            <p>采集时间：{{item.pick_time}}</p>
                            <p>采集地点：{{item.pick_space}}</p>
                            <p>采集经纬度：{{item.jing_dot}},{{item.wei_dot}}</p>
                        </td>
                        <td>
                            <p>{{item.det_item_name}}</p>
                        </td>
                        <td>
                            <p>{{item.executive}}</p>
                            <p>{{item.det_method_name}}</p>
                        </td>
                        <td>{{item.status_trun}}</td>
                        <td>
                            <a-space>
                                <a-button type="primary" @click="Set_specitem_lingqu(item)" v-if="item.status_lingqu === 2">
                                    领取任务
                                </a-button>
                                <a-button type="primary" @click="showDrawerApply(item)">申领样品</a-button>
                                <a-button type="danger" @click="showDrawerSendback(item)">退回样品</a-button>
                                <a-button type="primary" @click="jumpToJiance(item)">开始检测</a-button>
                                <template v-if="Number(item.create_check_table_id) != 0">
                                    <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick(item)">
                                        <template slot="title">确认删除该项？</template>
                                        <a-button type="danger" class="ml20">删除原始记录单</a-button>
                                    </a-popconfirm>
                                </template>
                            </a-space>
                            <div style="padding-top: 15px">
                                <p v-for="(iitem,key0) in item.spec_receive">{{iitem.text}}</p>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <a-pagination :current="page" :pageSize="pagesize" :total="count" v-if="count>pagesize" @change="onChange"/>
            </a-col>
        </a-row>

        <!--开始检测-->
        <div v-if="weituodan_info">
            <weituodan :weituodan_check_id="weituodan_check_id" :weituodan_check_ids="weituodan_check_ids" :weituodan_time="weituodan_time" :close="onCloseDrawerweituodan" :visible="drawer_weituodan"></weituodan>
        </div>

        <!--申领样品-->
        <div v-if="Apply_info">
            <Applysample :Apply_info="Apply_info" :Apply_time="Apply_time" :close="onCloseDrawerApply" :visible="drawer_visibleApply"></Applysample>
        </div>

        <!--退回样品-->
        <div v-if="Sendback_info">
            <Sendbacksample :Sendback_info="Sendback_info" :Sendback_time="Sendback_time" :close="onCloseDrawerSendback" :visible="drawer_visibleSendback"></Sendbacksample>
        </div>


        <!--时间轴-->
        <div v-if="info">
            <TimeLine :info="info" :close="onCloseDrawerT" :visible="drawer_visibleT"></TimeLine>
        </div>

    </div>
</template>

<script>

    import weituodan from "./weituodan/weituodan.vue";
    import Applysample from "./apply/apply.vue";
    import Sendbacksample from "./send-back/send-back.vue";
    import JPrint from "../../../components/print/JPrint";
    import TimeLine from "../../sampleguanli/jieshouauth/time-line/time-line.vue";


    export default {
        components: {
            Applysample, Sendbacksample, JPrint, TimeLine, weituodan
        },
        data() {
            return {
                noID: false,
                page: 1,
                count: '',
                pagesize: '',
                Samplecategory_list: [],
                Get_list_list: [],
                form: {
                    spec_type: [],
                    status: '',
                    keywords: '',
                },
                //value:[],
                // 定义控制抽屉显示的变量
                weituodan_check_ids: false,
                weituodan_check_id: false,
                weituodan_time: 0,
                weituodan_info: false,
                drawer_weituodan: false,

                Sendback_time: 0,
                Sendback_info: false,
                drawer_visibleSendback: false,

                drawer_visibleList: false,

                Apply_info: false,
                Apply_time: 0,
                drawer_visibleApply: false,

                info: false,
                drawer_visibleT: false,//流转时间轴

                templateList: [],//标签模板
                print_value: [],
            }
        },
        watch: {
            form: {
                handler() {
                    if (this.page_show) this.searchClick()
                },
                deep: true
            },
            $route: {
                handler() {
                    this.mountedDo()
                },
                deep: true
            },
        },
        mounted() {
            this.mountedDo()
        },
        methods: {
            ijumpToJiance() {
                //console.log('wwwwwwwwwwwww')
                let ids = []
                this.Get_list_list.map((item) => {
                    if (item.create_check) {
                        ids.push(item.id)
                    }
                })
                let check_id = ids.shift();
                let check_ids = ids;
                let tpl_id = 0;
                console.log({
                    check_id,
                    check_ids,
                    tpl_id,
                })
                this.showDrawerweituodan(check_id, check_ids)

            },
            jumpToJiance() {
                let id = 0
                let count = 0
                this.Get_list_list.map((item, key) => {
                    if (item.create_check) {
                        id = item.id
                        count++
                    }
                })
                if (!id) return layer.msg('请选择样品')
                if (count !== 1) return layer.msg('只能选择一个样品')
                console.log(987, id)

                this.$sa0.post({
                    url: this.$api('before_start_check'),
                    data: {
                        id: id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.$router.push('/startCheck/' + id)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            mountedDo() {
                this.page = Number(this.$route.query.page) || 1;
                this.form.keywords = this.$route.query.keywords || '';
                this.form.status = this.$route.query.status || '';
                this.form.spec_type = this.$route.query.spec_type ? this.$route.query.spec_type.split(',').map((item) => {
                    return Number(item)
                }) : [];
                console.log(this.$route.query.spec_type)
                this.Get_list()
                this.Samplecategoryalllist()
                this.Get_tag(3)
                setTimeout(() => {
                    this.page_show = true
                }, 100)
                this.Get_tag(3)
            },

            //搜索
            searchClick() {
                //console.log(this.form.spec_types.join(','))
                this.$router.push({
                    query: {
                        id: this.id,
                        status: this.form.status,
                        keywords: this.form.keywords,
                        spec_type: this.form.spec_type.join(','),
                        page: 1,
                    }
                })
            },
            Clear() {
                this.form.status = '';
                this.form.keywords = '';
                this.form.spec_type = [];
                this.page = 1;
            },
            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page,
                        keywords: this.form.keywords,
                        status: this.form.status,
                        spec_type: this.form.spec_type,
                    }
                })
            },

            Get_tag(id) {
                this.$sa0.post({
                    url: this.$api('Get_tag'),
                    data: {
                        tag_type: id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.templateList = response.data.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            PrintTAG(index) {
                let check_list = [];
                this.Get_list_list.map((item) => {
                    if (item.check) {
                        check_list.push({demo_num: item.in_number, demo_text: item.name})
                    }
                })
                if (check_list.length === 0) {
                    layer.msg('请选择要打印的样品编号')
                } else {
                    //
                    let template = this.templateList[index]
                    let src_list = check_list.map(item => {
                        template.demo_num = item.demo_num
                        template.demo_text = item.demo_text
                        return this.getImg(template)
                    })
                    console.log(check_list)
                    this.$refs.doPrint.printIt(src_list);
                }
            },

            getImg(item) {
                let data = {
                    i: item.demo_num,
                    pw: item.paper_w,
                    ph: item.paper_h,
                    bs: Number(item.isshow_barcode) !== 2,
                    qs: Number(item.isshow_qrcode) !== 2,
                    ts: Number(item.isshow_text) !== 2,
                }

                if (Number(item.isshow_barcode) !== 2) {
                    data.bw = item.barcode_w
                    data.bh = item.barcode_h
                    data.btm = item.barcode_top
                    data.blm = item.barcode_left
                }

                if (Number(item.isshow_qrcode) !== 2) {
                    data.qw = item.qrcode_w
                    data.qh = item.qrcode_h
                    data.qtm = item.qrcode_top
                    data.qlm = item.qrcode_left
                }
                if (Number(item.isshow_text) !== 2) {
                    data.tv = item.demo_text
                    data.tsi = item.text_size
                    data.ttm = item.text_top
                    data.tlm = item.text_left
                }
                let q_arr = [];
                // let url = 'http://aaa.aaa.aaa?aaa=1&aaa=2&aaa=2&aaa=2&aaa=2&aaa=2'
                // ['tsi=1','ttm=1','aaa=1','aaa=1','aaa=1']
                for (let i in data) {
                    q_arr.push(`${i}=${data[i]}`)
                }
                let q_str = q_arr.join('&')
                // 'aaa=1&aaa=2&aaa=2&aaa=2&aaa=2&aaa=2'
                let url = this.$api('qrCode') + '?' + q_str
                return url
            },


            //删除
            delClick(item) {
                this.$sa0.post({
                    url: this.$api('Del_check_table'),
                    data: {
                        create_check_table_id: item.create_check_table_id,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_list()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            Set_specitem_lingqu() {
                let id = 0
                let count = 0
                let status = 0
                this.Get_list_list.map((item, key) => {
                    if (item.create_check) {
                        id = item.id
                        status = item.state
                        count++
                    }
                })
                if (!id) return layer.msg('请选择样品')
                if (count !== 1) return layer.msg('只能选择一个样品')
                if (status !== 2) return layer.msg('未留样，不能领取任务')

                let data = {
                    id: id,
                }
                this.$sa0.post({
                    url: this.$api('Set_specitem_lingqu'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg(res.message)
                            this.Get_list();
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            //样品类型文件夹列表
            Samplecategoryalllist() {
                //console.log('---------------------------');
                this.$sa0.post({
                    url: this.$api('Samplecategoryalllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Samplecategory_list = response.data.list;
                            // this.Samplecategory_list = JSON.parse(response.data.list).map((item) => {
                            //     return item.toString()
                            // });
                            console.log(this.Samplecategory_list);
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // w检测人列表
            Get_list() {
                this.$sa0.post({
                    url: this.$api('Get_list'),
                    data: {
                        id: this.id,
                        page: this.page,
                        keywords: this.form.keywords,
                        status: this.form.status,
                        spec_type: this.form.spec_type[this.form.spec_type.length - 1],
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            //this.Get_list_list = response.data.result.list;
                            this.Get_list_list = response.data.result.list.map((item) => {
                                item.check = false
                                item.create_check = false
                                return item;
                            });
                            console.log(this.Get_list_list);
                            this.count = Number(response.data.result.count);//分页
                            this.pagesize = Number(response.data.result.pagesize);//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 定义 打开抽屉时的 函数  样品流转时间轴
            showDrawerweituodan(check_id, check_ids = []) {
                this.weituodan_check_id = check_id;
                this.weituodan_check_ids = check_ids;
                this.weituodan_time = new Date() / 1;
                this.drawer_weituodan = true;
                this.weituodan_info = true;
            },
            onCloseDrawerweituodan() {
                this.Get_list();
                this.drawer_weituodan = false;
                this.weituodan_info = false;
            },
            showDrawerSendback() {
                let id = 0
                let count = 0
                this.Get_list_list.map((item, key) => {
                    if (item.create_check) {
                        this.Sendback_info = item;
                        id = item.id
                        count++
                    }
                })
                if (!id) return layer.msg('请选择样品')
                if (count !== 1) return layer.msg('只能选择一个样品')
                console.log(987, id)
                this.Sendback_time = new Date() / 1;
                this.drawer_visibleSendback = true;
            },
            onCloseDrawerSendback() {
                this.Get_list();
                this.drawer_visibleSendback = false;
                this.Sendback_info = false;
            },
            // 定义 打开抽屉时的 函数  样品流转时间轴
            // showDrawerList() {
            //     //this.Apply_info = item;
            //     //this.Apply_time = new Date() / 1;
            //     this.drawer_visibleList = true;
            // },
            // onCloseDrawerList() {
            //     this.drawer_visibleList = false;
            //     //this.Apply_info = false
            // },
            // 定义 打开抽屉时的 函数  申领样品
            showDrawerApply() {
                let id = 0
                let count = 0
                this.Get_list_list.map((item, key) => {
                    if (item.create_check) {
                        this.Apply_info = item;
                        id = item.id
                        count++
                    }
                })
                if (!id) return layer.msg('请选择样品')
                if (count !== 1) return layer.msg('只能选择一个样品')
                console.log(987, this.Apply_info)

                this.Apply_time = new Date() / 1;
                this.drawer_visibleApply = true;
            },
            onCloseDrawerApply() {
                this.drawer_visibleApply = false;
                this.Apply_info = false
            },

            // 定义 打开抽屉时的 函数 时间轴
            showDrawerT(item) {
                this.info = item;
                console.log(this.info);
                this.drawer_visibleT = true;
            },
            onCloseDrawerT() {
                this.drawer_visibleT = false;
            },
        }
    }
</script>

<style scoped>
    .layui-table th{
        text-align: left
        }

    .print-tag{
        position: relative;
        }

    .print-show{
        display: none
        }

    .print-tag:hover .print-show{
        display: block;
        position: absolute;
        top: 36px;
        left: 0;
        width: 260px;
        height: auto;
        border: solid 1px #efefef;
        background: #fff;
        padding: 15px;
        z-index: 999;
        }

    table tr{ cursor: pointer}


    .btner{
        width: 100%;
        background: #f9f9f9;
        padding: 5px 10px;
        font-size: 12px;
        color: #333;
        border: 1px solid #eeeeee;
        margin-bottom: 10px;
        }

    .active{
        background: #96cbf9;
        }

    .btnerlist{
        cursor: pointer;
        border-right: 1px solid #eeeeee;
        height: 26px;
        line-height: 26px;
        padding: 0 10px
        }

    .btnerlist :last-child(1){
        border: none
        }

    .fcadd{
        color: #66c14c !important;
        font-size: 18px !important;
        }

    .btnerlist i{
        color: #999999;
        font-size: 14px;
        vertical-align: bottom;
        }

    .btnerlist:hover{
        background: #1592fe;
        color: #ffffff;
        border-radius: 4px;

        }

    .btnerlist:hover i, .btnerlist:hover.fcadd{
        color: #ffffff !important
        }

    .isthis{
        background: #1592fe;
        color: #ffffff;
        border-radius: 4px;
        }

    .isthis i{
        color: #ffffff
        }
</style>
