import { render, staticRenderFns } from "./weituodan.vue?vue&type=template&id=2441591c&scoped=true&"
import script from "./weituodan.vue?vue&type=script&lang=js&"
export * from "./weituodan.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.0_cutmzj7votxcrccm4agyl3addi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2441591c",
  null
  
)

export default component.exports