
<template>
  <div>
    <!--:visible="visible"  赋值 显示变量-->
    <!--@close="onClose"    赋值 关闭函数-->
    <a-drawer title="选择委托单" width="36%" placement="right" :closable="true" :visible="visible" @close="onClose">
      <div>
        <table class="layui-table">
          <thead>
          <tr>
            <th>委托单名称</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,key) in Get_check_tpl_list" v-if="item.is_del == 2">
            <td>{{item.name}}{{item.is_del}}</td>
            <td>
              <a-space>
                <a-button type="primary" @click="doClick(item)">选择</a-button>

              </a-space>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </a-drawer>

  </div>
</template>
<script>
  /***
   Name: example3
   Code: sa0ChunLuyu
   Time: 2021/10/16 09:47
   Remark: 编辑抽屉示例的抽屉组件
   */
  export default {
    // 接收 显示变量 和 关闭函数
    props: ['visible', 'close','weituodan_check_id','weituodan_check_ids'],
    data() {
      return {
        Get_check_tpl_list:''
      }
    },
    mounted() {
      this.Get_check_tpl()

    },
    methods: {
      //删除
      delClick(item) {
        this.$sa0.post({
          url: this.$api('Del_check_table'),
          data: {
            create_check_table_id: item.create_check_table_id,
          }
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              layer.msg(response.message)
              this.Get_check_tpl()
            },
            error: (response) => {
              layer.msg(response.message)
            },

          })
        })
      },
      //选择
      doClick(item) {
        //console.log(this.weituodan_info.id,item.id);
        let data = {
          check_id: this.weituodan_check_id,
          check_ids: this.weituodan_check_ids,
          tpl_id: item.id,
        }
        //console.log(data)
        //console.log(JSON.stringify(data))
        this.$sa0.post({
          url: this.$api('Create_check_table'),
          data: data,
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (res) => {
              layer.msg('已选择')
              this.$router.push('/startCheck/' + res.data.id)
              this.close()
            },
            error: (res) => {
              layer.msg(res.message)
            }
          })
        })
      },
      // w获取模板列表
      Get_check_tpl() {
        this.$sa0.post({
          url: this.$api('Get_check_tpl'),
          data: {
            id: this.id,
            search: this.search,
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.Get_check_tpl_list = response.data.list;
              // this.Get_list_list = response.data.result.list.map((item) => {
              //   item.check = false
              //   return item;
              // });
              //console.log(this.Get_check_tpl_list);
              //this.count = Number(response.data.result.count);//分页
              //this.pagesize = Number(response.data.result.pagesize);//分页
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      },


      onClose() {
        this.close();
      },
    }
  }
</script>
<style scoped>

</style>
