
<template>
  <div>
    <!--:visible="visible"  赋值 显示变量-->
    <!--@close="onClose"    赋值 关闭函数-->
    <a-drawer title="样品申领" width="36%" placement="right" :closable="true" :visible="visible" @close="onClose">
      <div>
        <a-form-model>
          <a-row>
            <a-col :span=12 >
              <a-form-model-item label="样品类型" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                  {{Apply_info.spec_type_name}}
              </a-form-model-item>
              <a-form-model-item label="样品编号" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Apply_info.spec_item_name}}
              </a-form-model-item>
              <a-form-model-item label="采集时间" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Apply_info.pick_time}}
              </a-form-model-item>
              <a-form-model-item label="采集经度" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Apply_info.jing_dot}}
              </a-form-model-item>
              <a-form-model-item label="接收时间" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Apply_info.get_time}}
              </a-form-model-item>

            </a-col>
            <a-col :span=12>
              <a-form-model-item label="样品名称" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Apply_info.spec_item_name}}
              </a-form-model-item>
              <a-form-model-item label="采集人" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Apply_info.caiji_name}}
              </a-form-model-item>
              <a-form-model-item label="采集地点" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Apply_info.pick_space}}
              </a-form-model-item>
              <a-form-model-item label="采集纬度" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Apply_info.wei_dot}}
              </a-form-model-item>
            </a-col>
          </a-row>

          </a-form-model>

          <a-form-model  :model="form" >
          <a-row>
            <a-col :span=24>
              <a-form-model-item label="申请备注" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <a-textarea  rows="3" v-model="form.remark"/>
              </a-form-model-item>

              <a-form-model-item label="" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:18, offset: 4 }">
                <a-space>
                  <a-button type="primary" @click="doClick()">申请领取</a-button>

                </a-space>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>


      </div>
    </a-drawer>

  </div>
</template>
<script>
  /***
   Name: example3
   Code: sa0ChunLuyu
   Time: 2021/10/16 09:47
   Remark: 编辑抽屉示例的抽屉组件
   */
  export default {
    // 接收 显示变量 和 关闭函数
    props: ['visible', 'close','Apply_info','Apply_time'],
    data() {
      return {
        form:{
          remark:'',
        }
      }
    },
    mounted() {

    },
    methods: {
      doClick() {
        console.log(this.Apply_info.id);
        let data = {
          id: this.Apply_info.id,
          remark: this.form.remark,
        }
        //console.log(data)
        console.log(JSON.stringify(data))
        this.$sa0.post({
          url: this.$api('Shenqing_specitem'),
          data: data,
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (res) => {
              layer.msg('已申领')
              this.close()
            },
            error: (res) => {
              layer.msg(res.message)
            }
          })
        })
      },

      onClose() {
        this.close();
      },
    }
  }
</script>
<style scoped>

</style>
